import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({
  bgColor = "var(--primary-color)",
  onclick,
  title,
  path,
  isTarget = false,
}) => {
  return (
    <Link to={path} target={isTarget ? "_blank" : null}>
      <Button
        type={"primary"}
        onClick={onclick}
        style={{ background: bgColor, boxShadow: "none", color: "#fff" }}
      >
        {title}
      </Button>
    </Link>
  );
};

export default CustomLink;
