import React from "react";
import TitleHeadSpecialHomePage from "./TitleHeadSpecialHomePage";
import Fade from "react-reveal/Fade";
// import ReactImageGallery from "react-image-gallery";
import { Carousel } from "antd";

export const dataCepanel = [
  "لوحة تحكم لمدير النظام",
  "لوحة تحكم للمنظم",
  "لوحة تحكم لماسح QR",
];
const SectionCpanel = () => {
  const slideImages = [
    "../../images/slide-01.png",
    "../../images/slide-02.png",
    "../../images/slide-03.png",
    // "../../images/slide-04.png",
  ];
  const contentStyle = {
    margin: 0,
    height: "250px",
    color: "#fff",
    maxWidth: "100%",
    lineHeight: "250px",
    textAlign: "center",
    background: "var(--bg-body)",
  };
  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  return (
    <div className="container p-3 p-md-5 " id="cepanel-homepage">
      <TitleHeadSpecialHomePage title={"لوحات التحكم"} />
      {/* col one */}
      <div className="row mt-0 mt-md-5">
        <div className="col-12 col-lg-6 nav nav-bar d-flex flex-column">
          {dataCepanel.map((item, i) => (
            <Fade bottom key={i}>
              <li className="my-2">
                <span>
                  <img
                    src="../../images/icon-goal-homepage.png"
                    alt="icon-goal"
                    className="ms-2"
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                  />
                </span>
                <span className="fw-bold text-sub-title text-dark text-opacity-75">
                  {item}
                </span>
              </li>
            </Fade>
          ))}
        </div>
        <div className="col-12 col-lg-6">
          {/* <div className=" p-4 d-flex align-items-center justify-content-center flex-column rounded-4 m-5">
            <ReactImageGallery
              items={slideImages}
              showPlayButton={false}
              showFullscreenButton={false}
              showBullets={true}
              showThumbnails={false}
            />
          </div> */}
          <Carousel afterChange={onChange}>
            {slideImages.map((item, i) => (
              <div
                key={i}
                className="d-flex justify-content-center align-items-center "
              >
                <img style={contentStyle} src={item} alt={item} />
              </div>
            ))}
          </Carousel>
          {/* <div
            id="carouselExampleIndicators"
            className="carousel slide mt-4 mt-lg-0"
            data-bs-ride="true"
            dir="ltr"
          >
            <div className="carousel-indicators">
              {slideImages.map((_, i) => (
                <div
                  key={i}
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={i}
                  className={i === 0 ? "active" : null}
                  aria-current="true"
                  aria-label={`Slide ${i + 1}`}
                  button={"button"}
                ></div>
              ))}
            </div>
            <div className="carousel-inner">
              {slideImages.map((item, i) => (
                <div
                  key={i}
                  className={`carousel-item ${i === 0 ? "active" : null}`}
                >
                  <img
                    src={item}
                    className="d-block w-100"
                    alt="..."
                    style={{
                      height: "300px",
                      maxWidth: "100%",
                    }}
                  />
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SectionCpanel;
