import React from "react";
import Footer from "../../components/home/Footer";
import SubscriptionsContainer from "../../components/Subscriptions/SubscriptionsContainer";
import FuncScrollToUp from "../../components/utility/FuncScrollToUp";

const SubscriptionsPage = () => {
  FuncScrollToUp();

  return (
    <div>
      <div className="container p-3 p-md-5">
        <SubscriptionsContainer />
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionsPage;
