import React from "react";
import Fade from "react-reveal/Fade";

const TitleHeadSpecialHomePage = ({ title }) => {
  return (
    <Fade bottom>
      <div className="title-head-special">
        <div>{title}</div>
      </div>
    </Fade>
  );
};

export default TitleHeadSpecialHomePage;
