/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Layout } from "antd";
import Hamburger from "hamburger-react";
import HomeSidebar from "./HomeSidebar";
import { appName } from "../../App";
const { Header } = Layout;
const HeaderHomePage = ({ refScroll }) => {
  const activesMenu = (e) => {
    let bars = document.querySelector(".bars");
    bars.classList.toggle("active");
  };
  const [collapseWidth, setCollapseWidth] = useState(768);

  const getWidth = () => {
    setCollapseWidth(window.innerWidth);
  };

  useEffect(() => {
    // console.log(window.innerWidth);
    // console.log(collapseWidth);
    window.addEventListener("resize", getWidth);
    if (collapseWidth < 768) {
      // setCollapseWidth(window.innerWidth)
      // setCollapse(true);
    } else {
      // setCollapse(false);
    }
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [window.innerWidth]);
  const headerItem = [
    {
      title: "الرئيسية",
      path: "/",
      isLink: true,
    },
    {
      title: `عن ${appName}`,
      path: refScroll[0],
      isLink: false,
    },
    {
      title: "لوحات التحكم",
      path: refScroll[1],
      isLink: false,
    },
    {
      title: "مايميزنا",
      path: refScroll[2],
      isLink: false,
    },

    {
      title: "الاشتراكات",
      path: refScroll[3],
      isLink: false,
    },
    {
      title: "تواصل معنا",
      path: refScroll[4],
      isLink: false,
    },
  ];

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="container">
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          // marginRight: window.innerWidth > 768 ? 230 : 0,
          background: "#fff",
          height: 80,
          // background: theme === "dark" ? "var(--green)" : "var(--primary)",
        }}
        className="d-flex justify-content-between p-0 m-0 w-100"
      >
        <div
          className="navbar-na d-flex w-100 p-0 m-0"
          style={{ listStyle: "none" }}
        >
          <Link
            className="navbar-brand"
            to={"/"}
            style={{ width: "120px", height: "80px" }}
          >
            <img
              src="../../../images/logo.png"
              style={{ maxWidth: "100%" }}
              alt="logo"
            />
          </Link>
          {headerItem.map((item, i) => (
            <li className="m-2 d-none d-md-block" key={i}>
              {item.isLink ? (
                <Link
                  to={item.path}
                  className="nav-link fw-bold text-dark text-end text-md-center mx-0 mx-lg-3"
                >
                  {item.title}
                </Link>
              ) : (
                <div
                  onClick={item.path}
                  type="button"
                  className="nav-link fw-bold text-dark text-end text-md-center mx-0 mx-lg-3"
                  aria-current="page"
                >
                  {item.title}
                </div>
              )}
            </li>
          ))}
        </div>
        {window.innerWidth > 768 ? (
          <div></div>
        ) : (
          <div
            className=" border rounded"
            style={{ color: "var(--primary-color)" }}
          >
            <Hamburger toggled={open} toggle={setOpen} />
          </div>
        )}
        <Drawer
          title=""
          placement="right"
          onClose={onClose}
          open={open}
          width={230}
          style={{
            background: "#fff",
          }}
        >
          <div
            style={{
              height: "100%",
            }}
            className="d-flex justify-content-between flex-column style-sidebar-scroll-radius"
          >
            <HomeSidebar isPositionFixed={false} refScroll={refScroll} />
          </div>
        </Drawer>
      </Header>
    </div>
  );
};

export default HeaderHomePage;

// <div className="container">
// <Link className="navbar-brand">
//   <img
//     src="../../../images/logo.png"
//     style={{ width: "110px", height: "70px" }}
//     alt="logo"
//   />
// </Link>
//   <button
//     className="navbar-toggler shadow-none border-0"
//     // style={{ borderWidth: "3px", borderColor: "var(--primary-color)" }}
//     style={{ backgroundColor: "var(--primary-color)" }}
//     type="button"
//     data-bs-toggle="collapse"
//     onClick={activesMenu}
//     data-bs-target="#navbarSupportedContent"
//     aria-controls="navbarSupportedContent"
//     aria-expanded="false"
//     aria-label="Toggle navigation"
//   >
//     {/* <span className="navbar-toggler-icon"></span> */}
//     <div className="bars">
//       <div className="bg-white"></div>
//       <div className="bg-white"></div>
//       <div className="bg-white"></div>
//       {/* <img
//           src="../../images/menu-white.png"
//           style={{ width: "35px", height: "35px" }}
//           alt=""
//         /> */}
//     </div>
//   </button>
//   <div
//     className="collapse navbar-collapse p-0 py-2 "
//     id="navbarSupportedContent"
//   >
//     <div className="navbar-nav me-0 me-md-5 p-2 mb-lg-0">
//       {headerItem.map((item, i) => (
//         <li className="nav-item mt-1 mt-md-2" key={i}>
//           {/* {i < headerItem.length - 1 ? ( */}
//           <div
//             onClick={item.path}
//             type="button"
//             className="nav-link fw-bold text-dark text-end text-md-center mx-0 mx-lg-3"
//             aria-current="page"
//           >
//             {item.title}
//           </div>
//         </li>
//       ))}
//       <li className="nav-item mt-1 mt-md-2 d-block d-md-none">
//         <Link
//           to={"/login"}
//           className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3 w-100"
//           id="style-btn-start-service"
//         >
//           بدء الخدمة
//         </Link>
//       </li>
//     </div>
//   </div>
// </div>
