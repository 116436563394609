/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Fade from "react-reveal/Fade";
import { appName } from "../../App";

const About = () => {
  return (
    <div
      className="container-fluid p-0 m-0 position-relative  text-white"
      id="about-homepage"
      style={{ backgroundColor: "var(--primary-color)" }}
    >
      <div className="Triangles"></div>
      <div className="container d-flex flex-column p-4 p-md-5  flex-md-row">
        <div className=" d-flex justify-content-center align-items-center">
          {/* <lottie-player
            src="https://lottie.host/0c84e6f5-c477-48cb-9b46-27cba2fbf0f5/LE3m3u60Yz.json"
            background="transparent"
            speed="1"
            // style={{ width: "300px", height: "300px" }}
            loop
            class="style-img-goal"
            autoplay
          ></lottie-player> */}
          <div
            style={{ width: "150px", height: "150px" }}
            className=" d-flex justify-content-center align-items-center"
          >
            <img
              src="../../../images/qr-code.png"
              style={{ maxWidth: "100%" }}
              alt="logo"
            />
          </div>
        </div>
        {/* text */}
        <Fade center>
          <div className="style-margin-about-text p-2 p-md-5 text-center text-md-end">
            <h1 className=" fw-bold">{appName}</h1>
            <div
              className=" text-sub-titl  mt-3 text-white fw-bold lh-lg"
              style={{ fontSize: 14 }}
            >
              هو نظام تستطيع إنشاء جداول زمنية للفعاليات و الأنشطة و يسمح النظام
              بتسجيل الحضور وإدارة تسجيل الحضور بسهولة عن طريق خاصية QR
              <span className="mx-2">
                <img
                  src="../../images/icon-Quotation-mark.png"
                  style={{ width: "30px", height: "30px" }}
                  alt=""
                />
              </span>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default About;
