import HomePage from "./pages/home/HomePage";
import Page404 from "./pages/page404/Page404";
import SubscriptionsPage from "./pages/subscription/SubscriptionsPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

export const appName = "تكتي";
function App() {
  const RouteWithElementComponentIndex = [
    {
      path: "/",
      component: <HomePage />,
    },
    {
      path: "/subscription",
      component: <SubscriptionsPage />,
    },
  ];
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {RouteWithElementComponentIndex.map((item, i) => (
            <Route key={i} path={item.path} element={item.component} />
          ))}

          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
      <NotificationContainer />
    </div>
  );
}

export default App;
