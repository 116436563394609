import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuItem } from "react-pro-sidebar";

const SidebarItem = ({
  title,
  icon,
  iconActive,
  path,
  isHideNotification,
  valueNotification,
  onClick,
}) => {
  const location = useLocation();

  const url = "/" + location.pathname.split("/").splice(1, 2).join("/");

  return (
    <MenuItem
      onClick={onClick}
      component={<Link to={path} />}
      style={{ width: 230 }}
      active={url === path ? true : false}
      icon={url === path ? iconActive : icon}
    >
      <div className="fw-bold" title={title} style={{ fontSize: "14px" }}>
        {title}
      </div>
      {isHideNotification && valueNotification > 0 ? (
        <span
          className="position-absolute  badge rounded-pill bg-danger"
          style={{
            left: "7px",
            top: "14px",
          }}
        >
          {valueNotification}
        </span>
      ) : null}
    </MenuItem>
  );
};

export default SidebarItem;
