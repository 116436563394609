import React from "react";
import { Input } from "antd";

const CustomInput = ({
  type,
  label,
  placeholder,
  icon,
  name,
  onChange,
  value,
  readOnly,
  onblur,
  msgError,
  disabled,
}) => {
  return (
    <>
      <div className="my-1 fw-bold">{label}</div>
      <Input
        type={type}
        placeholder={placeholder}
        prefix={icon}
        onChange={onChange}
        size="large"
        value={value}
        disabled={disabled}
        name={name}
        readOnly={readOnly}
        onBlur={onblur}
      />
      <span className="fw-bold " style={{ color: "var(--red)", fontSize: 13 }}>
        {msgError}
      </span>
    </>
  );
};

export default CustomInput;
