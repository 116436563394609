/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Tilt from "react-parallax-tilt";
import DateAndDay from "../utility/DateAndDay";
import CustomLink from "../utility/CustomLink";
import { appName } from "../../App";
const HLanding = () => {
  let text = [
    "نساهم في صناعة المستقبل",
    "رحـلتـك الأولـى لـبدايـتك الـمهنيـة",
    "وجـهتـك لـتحقيـق كـل نـجـاح",
  ];

  const [valText, setvalText] = useState("نساهم في صناعة المستقبل");
  // const [getDay, setGetDay] = useState("");

  useEffect(() => {
    setInterval(() => {
      let randomNumber = Math.floor(Math.random() * text.length);
      setvalText(text[randomNumber]);
    }, 4000);
  }, []);

  return (
    <div
      className="position-relative"
      style={{
        backgroundImage: "url(../../images/landing-img.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <div
        className="position-absolute"
        style={{
          width: "100%",
          height: "100%",
          background: "#000",
          opacity: 0.6,
        }}
      ></div>

      <div className="container" style={{ paddingTop: 80, zIndex: 10 }}>
        <div className="d-flex justify-content-evenly align-items-center  flex-column height-landing-page mt-5 mt-md-0 p-3 p-md-5">
          <div className="d-flex justify-content-between flex-column flex-md-row w-100">
            <div className="d-flex justify-content-center flex-column text-white align-items-center align-items-md-start order-last order-md-first  mt-md-0">
              <h1
                className=" fw-bold landing-text"
                style={{
                  zIndex: 10,
                  textShadow: "2px 2px #ffffff8f",
                }}
              >
                {appName}
              </h1>
              <div
                className="fw-bold text-center w-100 text-white scalc-text-landing my-3"
                style={{ zIndex: 10 }}
              >
                {valText}
              </div>

              <CustomLink
                title={"بدء الخدمة"}
                path={"https://tickety.osoolsys.sa/"}
                isTarget={true}
              />
            </div>
            <Tilt scale={1}>
              <div>
                <img
                  src={"../../images/slide-01.png"}
                  alt="img-my"
                  className="style-landing-img"
                />
              </div>
            </Tilt>
          </div>

          <div className=" text-white mt-4" style={{ zIndex: 10 }}>
            <h4>تاريخ اليوم</h4>
            <div className="mt-3">
              <DateAndDay color={"#fff"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HLanding;

// <div
//   className=""
// style={
//   {
//     // backgroundImage: "url('../../images/bg-3.jpg')",
//     // backgroundImage: "url('../../images/bg-landing-blur.png')",
//     // backgroundRepeat: "no-repeat",
//     // minHeight: "60vh",
//     // backgroundSize: "100% 100%",
//     // filter: "blur(5px)",
//   }
// }
// >
//   <div
//     className="container  d-flex align-items-center bg-dange p-3 p-md-5"
//     style={{
//       minHeight: "95vh",
//     }}
//   >
//     {/* <div
//       className="bg-success"
//       // style={{ minHeight: "100vh" }}
//     > */}
//     {/* col one text */}
//     <div
//       className="  w-100 d-flex justify-content-between style-shadow-large-extra flex-column position-relative"
//       style={{
//         backgroundImage:
//           "linear-gradient( 135deg, var(--primary-color) 10%, var(--primary-color-opacity) 100%)",
//         minHeight: "75vh",
//         borderRadius: "20px",
//         margin: "100px 0 50px 0",
//       }}
//     >
//       <div
//         className="position-absolute h-100 "
//         style={{
//           right: "0",
//           top: "0",
//         }}
//       >
//         <img
//         // src="../../images/wavy-landing-01.png"
//         // className="h-100 wavy-width-landing"
//         // alt=""
//         />
//       </div>
//       <div
//         className="position-absolute h-100 "
//         style={{
//           right: "0",
//           top: "0",
//         }}
//       >
//         <img
//         // src="../../images/wavy-landing-02.png"
//         // className="h-100 wavy-width-landing "
//         // alt=""
//         />
//       </div>
//       <div
//         className="position-absolute h-100 "
//         style={{
//           right: "0",
//           top: "0",
//         }}
//       >
//         <img
//         // src="../../images/wavy-landing-03.png"
//         // className="h-100 wavy-width-landing"
//         // alt=""
//         />
//       </div>
//       {/* logo-tvtc-without-title */}
//       <PositionedImage
//         src={"../../images/logo-tvtc-without-title.png"}
//         width={"70px"}
//         height={"70px"}
//         alt={"logo-tvtc-without-title"}
//         top={"10px"}
//         left={"20px"}
//         bottom={""}
//         right={""}
//         rotate={"0"}
//       />
//       {/* triangle */}
//       {/* <PositionedImage
//         src={"../../images/bg-triangle.png"}
//         width={"60px"}
//         height={"60px"}
//         alt={"triangle"}
//         top={"0"}
//         left={""}
//         bottom={""}
//         right={"40px"}
//         rotate={"180deg"}
//       /> */}
//       <div className="d-flex justify-content-center">
//         <div>
//           <lottie-player
//             src="https://lottie.host/65ba24b7-1281-417a-9c7b-e15eff962b03/li0kuEawH0.json"
//             background="transparent"
//             speed="1"
//             className="landing-page-img"
//             loop
//             autoplay
//           ></lottie-player>
//         </div>
//       </div>
//       <div className="d-flex justify-content-center flex-column align-items-center">
//         <div className="fs-3 fw-bold text-center w-100 text-white scalc-text-landing">
//           {valText}
//         </div>
//         <div className="d-flex justify-content-center  w-100">
//           <Link
//             to={"/login"}
//             className="btn fw-bold rounded-5 style-shadow-large bg-whit text-center px-4 px-md-5 py-2 mt-4"
//             id="style-btn-landing"
//           >
//             بدء الخدمة
//           </Link>
//         </div>
//       </div>
//       <div className="d-flex justify-content-center px-3 my-3">
//         <DateAndDay color={"#fff"} />
//       </div>
//     </div>
//   </div>
// </div>
