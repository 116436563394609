import { Button } from "antd";
import React from "react";

// const CustomButton = ({
//   title,
//   onclick,
//   onchange,
//   value,
//   target,
//   width,
//   bgColor = "var(--primary-color)",
//   bgHover = "var(--primary-color)",
//   color = "var(--primary-color)",
//   colorHover = "#fff",
// }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   return (
//     <button
//       style={{
//         background: isHovered ? bgHover : "#fff",
//         width: width,
//         border: `3px solid ${isHovered ? bgHover : bgColor}`,
//         color: isHovered ? colorHover : color,
//         padding: `5px ${width === 40 ? "0" : "15"}px`,
//         borderRadius: "50px",
//         transition: "var(--transition)",
//         fontWeight: "bold",
//         cursor: "pointer",
//         fontSize: 16,
//       }}
//       // className="hover-btn-pill"
//       onClick={onclick}
//       onChange={onchange}
//       value={value}
//       formTarget={target}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       {title}
//     </button>
//   );
// };

const CustomButton = ({
  bgColor = "var(--primary-color)",
  onclick,
  title,
  className,
  isLoading = false,
}) => {
  return (
    <Button
      loading={isLoading}
      type={"primary"}
      typeof="button"
      onClick={onclick}
      className={className}
      style={{
        background: bgColor,
        boxShadow: "none",
        color: "#fff",
      }}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
