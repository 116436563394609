/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import SubscriptionsForms from "./SubscriptionsForms";
import { CheckCircleFilled, ArrowRightOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const SubscriptionsContainer = () => {
  const location = useLocation();
  const [dataSubscriptions, setDataSubscriptions] = useState(location.state);

  return (
    dataSubscriptions && (
      <div
        style={{
          minHeight: "100vh",
        }}
        className="col-12 col-lg-8 m-auto"
      >
        <div>
          <span>
            <Link
              style={{ textDecoration: "none", color: "var(--primary-color)" }}
              to={"/"}
            >
              <span className="mx-2">
                <ArrowRightOutlined style={{ color: "var(--primary-color)" }} />
              </span>
              العودة
            </Link>
          </span>
        </div>
        <div>
          <div className=" d-flex flex-column jusitfy-content-center align-items-center ">
            <div className="fw-bold" style={{ color: "var(--primary-color)" }}>
              <span className="fs-1 ">{dataSubscriptions.myData.price}</span>
              <span>ريال</span>
            </div>
            <div>
              <div>{dataSubscriptions.myData.month}</div>
            </div>
          </div>
          <div className="fw-bold fs-5 ">مميزات الباقة :</div>

          {dataSubscriptions.myData.AdvandagesItems.map((item, i) => (
            <div key={i} className="fw-bold">
              <span className="">
                <CheckCircleFilled style={{ color: "var(--primary-color)" }} />
              </span>
              <span className="mx-2" style={{ fontSize: 12 }}>
                {item}
              </span>
            </div>
          ))}
        </div>

        <SubscriptionsForms
          inputSubscriptions={
            dataSubscriptions.myData.price +
            " ريال - " +
            dataSubscriptions.myData.month
          }
        />
      </div>
    )
  );
};

export default SubscriptionsContainer;
